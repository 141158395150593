.navbar
  &.scrolled
    .navbar-brand
      img
        transition: height 150ms linear
        height: rem(100px) !important
  .container-fluid
    transition: background-color 200ms linear
    .navbar-brand
      img
        margin-left: rem(30px)
        height: rem(165px)
    .navbar-collapse
      .navbar-nav
        .nav-link
          margin-right: rem(65px)
          font-size: rem($font-size-header)
          text-align: center
          .active-link
            text-align: center
            height: 1px
            width: 0%
            background-color: #fff
            margin: 10px auto
          &:hover
            .active-link
              width: 90%
          &.active
            .active-link
              width: 90%
  &.scrolled
    background-color: rgb(0, 0, 0, 80%)
    transition: background-color 200ms linear
