// @media all and (min-width: 320px) and (max-width: 480px) and (orientation:landscape)
@media (max-width: $breakpoint-tablet)
  html
    overflow-x: hidden
  body
    width: 100%
    overflow-x: hidden
    position: relative
    -webkit-box-sizing: border-box
       -moz-box-sizing: border-box
            box-sizing: border-box
  #carousel-home, #carousel-trainings, #carousel-lawyers
    padding-top: 15%
  .navbar
    padding: 0
    &.scrolled
      .navbar-brand
        position: relative
        max-width: rem(180px)
        margin-left: rem(20px)
         
    .navbar-brand
      margin-right: rem(0px)
      position: relative
      max-width: rem(180px)
      margin-left: rem(99px)
      img
        margin-left: auto !important
    .container-fluid
      background-color: $black
      .navbar-collapse
        background-color: $black
        .navbar-nav
          .nav-link
            margin-right: 0 !important
  .navbar-dark 
    .navbar-toggler
      margin-right: rem(20px)
  
  .home-content 
    .history
      padding-bottom: 0rem
      p
        @include font-size(14px)

    .history, .history-items 
      margin: 0 auto
      @include font-size(14px)
      .first-letter
        @include font-size(14px)

  .footer-full-size
    height: fit-content
    padding-bottom: rem(20px)
    .wapp-cta
      z-index: 1
    .container
      .maps
        .card-body
          p
            line-height: 1.5
  .rrss
    height: rem(55px)
    width: 100vw
    a
      position: absolute !important
    img
      
      &.fb
        left: rem(30px) !important
        top: rem(13px) !important
      &.insta
        left: rem(60px) !important
        top: rem(10px) !important
    .translate-middle
      transform: initial !important
    .start-50
      left: inherit !important
    .top-50
      top: inherit !important
    .end-0
      right: inherit !important
    .disclaimer
      position: absolute !important
      left: rem(200px)
      top: rem(15px)
      p
        width: rem(155px)
        top: rem(10px) !important
        line-height: 0
    a
      position: inherit
  .service-detail-container
    width: 100%
    bottom: initial
    height: 110vh
    .close_icon
      &:hover
        width: inherit
        content: inherit
        animation: none
  
  #banner-home,#banner-trainings,#banner-lawyers
    display: inline-block
    margin-top: rem(calc(100px + 32px))
    // border: 1px solid blue

  #banner-home
    .carousel-caption
      left: 0
      bottom: 0
      font-size: 13px
      padding: 25px
      width: 95%
  #banner
    &.lawyers
      .carousel
        &.pointer-event
          height: 30vh
      .carousel-item
        img
          height: 30vh
    .carousel.pointer-event
      height: 100vh
    .carousel-caption
      width: 100%
      padding: rem(18px)
      left: 0 !important

    .carousel-item
      img
        height: 100vh
  .home-content
    h1
      margin-left: rem(15px)
      margin-bottom: rem(35px)
    .services
      .cover-background
        &.lawyers
          .transparent
            height: rem(175px)
            position: absolute !important
            width: 100%
            bottom: rem(100px)
            transform: translateY(58%)
            overflow: hidden
            h4
              margin-left: 0
              height: 1rem
              @include font-size(15px)

            .quote
              margin-top: rem(8px)
              margin-bottom: rem(8px)
          .show-more
            height: 40px
            position: absolute
            // border: 1px solid red
            top: rem(133px)
      .transparent
        h4
          color: $white
          @include font-size(20px)
          font-weight: 300
          margin-top: rem(16px)
          margin-left: rem(20px)
      .more-info
        position: relative
        button
          position: absolute
          top: 10px
          right: 22px
      .show-more
        background-color: rgb(0 0 0 / 0%) !important
        .btn-lg
          padding: rem(6px 30px)
          font-size: rem(15px)

  .service-detail-container
    .service-detail-indications
      padding: rem(42px 10px 0 12px)
      .text-over-transparent
        .title
          p:first-child
            // width: rem(205px)

  .abogado-detail-container
    width: 100%
    height: 100vh
    background-color: rgb(40 40 40 / 60%)
    overflow-x: hidden
    .abogado-detail-indications
      height: 100%
      padding: rem(78px 10px 0 10px)
      .long-description.text-over-transparent .title .col-md-12 p
        font-size: 13px 
      .text-over-transparent
        .title
          background-color: rgb(92 92 92 / 70%)
        &.container
          padding: 0
          margin-top: rem(18px)

  ul
    padding-left: rem(5px)