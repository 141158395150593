.service-detail-container
  position: fixed
  width: 90%
  height: 75vh
  visibility: hidden
  overflow-y: auto
  opacity: 0
  z-index: 1031
  background-color: #fff
  transition: opacity 0.3s ease-in-out
  -webkit-transition: opacity 0.3s ease-in-out
  -moz-transition: opacity 0.3s ease-in-out
  -o-transition: opacity 0.3s ease-in-out
  transition: opacity 0.3s ease-in-out
  // overflow: hidden
  top: 0
  left: 0
  margin: auto
  right: 0
  bottom: 0
  &.open
    opacity: 1
    visibility: inherit
  .close-cover
    position: absolute
    top: rem(25px)
    right: rem(8px)
    margin: auto
    cursor: pointer
    z-index: 1
    .close_icon
      width: rem(20px)
      transition: width 0.3s ease-in-out
      -webkit-transition:  width 0.3s ease-in-out
      -moz-transition:  width 0.3s ease-in-out
      -o-transition:  width 0.3s ease-in-out
      transition:  width 0.3s ease-in-out
      &:hover
        width: rem(21px)
        animation-name: spin
        animation-duration: 4000ms
        animation-iteration-count: infinite
        animation-timing-function: linear 
  .service-detail-actions
    position: relative
    display: inline-block
    width: 100%
    margin-top: 5%
    margin-bottom: 3%
    cursor: pointer
    a 
      color: inherit 
      text-decoration: inherit
      display: inline-block
      width: auto
      font-size: rem(11px)
      cursor: pointer
      p
        margin-bottom: 0
        width: auto
        display: inline-block
  .service-detail-indications
    position: relative
    display: inline-block
    padding: rem(42px 20px 0 42px)
    width: 100%
    font-weight: bold
    .text-over-transparent
      padding-top: rem(10px)
      .title
        // margin-bottom: rem(80px)
        height: rem(75px)
        border-radius: 20px
        background-color: rgb(0, 0, 0, 47%)
        position: relative
        p:first-child
          @include font-size(25px)
          font-weight: 300
          top: inherit
    .long-description
      &.text-over-transparent
        padding-top: rem(30px)
        margin-bottom: rem(40px)
        .title
          height: fit-content
          .col-md-12
            padding: 25px
            p
              text-align: left
              font-weight: 300
              @include font-size(16px)

@keyframes spin
  from
    transform: rotate(0deg)
  to
    transform: rotate(360deg)
  