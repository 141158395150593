.home-content
  // display: inline-block
  h1
    color: $white
    @include font-size(30px)
    margin-bottom: rem(35px)
  .history,.history-items
    padding-top: rem(40px)
    padding-bottom: rem(40px)
    color: $white
    font-weight: 300
    text-align: center
  .history-items
    .icon-container
      @include font-size(15px)
      .icon
        height: rem(130px)
        img
          height: rem(100px)

  .services
    padding-top: rem(40px)
    padding-bottom: rem(40px)
    .row
      padding-bottom: rem(50px)
    .cover-background
      cursor: pointer
      height: rem(300px)
      position: relative
      overflow: hidden
      .more-info
        &:hover
          transform: translateY(0%)
        .show-more
          display: inline-block
          position: absolute
          width: 100%
          left: 0
          bottom: 0
          font-size: rem(13px)
          border-radius: 0
          background-color: rgb(0 0 0 / 56%)
      .transparent
        height: 100%
        transform: translateY(80%)
        overflow: hidden
        h4
          margin-top: rem(10px)
          font-size: rem($font-size-header + 2px)
          // margin-left: rem(22px)
        .short-description
          margin-top: rem(30px)

      &.lawyers
        height: rem(450px)
        .more-info
          &:hover
            transform: translateY(100%)
        .transparent
          height: 225px
          transform: translateY(150%)
          overflow: hidden
          h4
            height: rem(42px)
            margin-top: rem(10px)
            margin-bottom: rem(10px)
            @include font-size(18px)

            // 62px + 65px = 127px
          .short-description
            margin-top: rem(20px)
            margin-bottom: rem(20px)
            height: rem(25px)
          .quote
            font-size: 12px
            margin-top: rem(18px)

#icons
  .col-md-12
    @include font-size(18px)
    font-weight: 200
    .text-start
      margin-top: rem(40px)