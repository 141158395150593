.container-fluid
  padding-right: 0
  padding-left: 0
.full-size
  width: 100vw
  // height: 100vh
  box-sizing: border-box
  // z-index: -1
  background-image: linear-gradient(to right, rgb(59, 59, 59), rgb(47, 47, 47), rgb(35, 35, 35), rgb(24, 24, 24), rgb(12, 12, 12), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0), rgb(0, 0, 0))
#banner-home,#banner-trainings,#banner-lawyers
  display: inline-block
.carousel
  .carousel-indicators 
    button
      width: 10px
      height: 10px
      border-radius: 100%
.carousel-inner
  .carousel-item
    .carousel-caption
      width: rem(415px)
      bottom: rem(165px)
      left: 10%
      p 
        text-align: left