@charset "UTF-8"
*, ::before, ::after
  box-sizing: inherit

body
  margin: 0
  font-family: $font-family-base
  font-size: $font-size-base
  font-weight: $font-weight-base
  line-height: $line-height-base
  color: $text-color
  text-align: left
  background-color: $black
  box-sizing: border-box
  overflow-x: hidden

.resizeable-both
  @include resizable(both)
  border: 1px solid $gray-400
  display: block

.first-letter
  font-size: rem($font-size-header + 2px)
  font-weight: bold

.transparent
  background-color: rgb(0, 0, 0, 47%)
  color: $white

.cover-background
  background-position: center
  background-repeat: no-repeat
  background-size: cover

.translate-up-transition
  transition: transform 0.3s ease-in-out
  -webkit-transition:  transform 0.3s ease-in-out
  -moz-transition:  transform 0.3s ease-in-out
  -o-transition:  transform 0.3s ease-in-out
  transition:  transform 0.3s ease-in-out

.width-100-transition
  transition: width 0.3s ease-in-out
  -webkit-transition:  width 0.3s ease-in-out
  -moz-transition:  width 0.3s ease-in-out
  -o-transition:  width 0.3s ease-in-out
  transition:  width 0.3s ease-in-out

.show-right-transition
  transition: right 0.3s ease-in-out
  -webkit-transition:  right 0.3s ease-in-out
  -moz-transition:  right 0.3s ease-in-out
  -o-transition:  right 0.3s ease-in-out
  transition:  right 0.3s ease-in-out

.parallax
  /* The image used */
  // background-image: url("img_parallax.jpg")

  /* Set a specific height */
  min-height: 500px

  /* Create the parallax scrolling effect */
  background-attachment: fixed
  background-position: center
  background-repeat: no-repeat
  background-size: cover


.text-over-transparent
  &.container
    .title
      height: rem(160px)
      border-radius: 20px
      background-color: rgb(0, 0, 0, 47%)
      position: relative
      p
        text-align: center
        color: $white
      p:first-child
        @include font-size(32px)
        font-weight: bolder
      p:nth-child(2)
        @include font-size(18px)
        top: 50% !important
        padding-top: rem(50px)