.footer-full-size
  width: 100vw
  height: rem(700px)
  box-sizing: border-box
  position: relative
  // border: 1px solid red
  z-index: 1
  background-position: center
  background-repeat: no-repeat
  background-size: cover
  .container
    padding-top: rem(55px)
    .title
      margin-bottom: rem(80px)
      p:first-child
        top: 35% !important
    .maps
      .card
        background-color: inherit
        .map-background
          height: rem(160px)
          div
            height: inherit
            background-position: center
            background-repeat: no-repeat
            background-size: 125%
      .card-body
        p
          line-height: 0.5
          text-align: center
          font-weight: 200
        

  .wapp-cta
    background-color: $white
    height: rem(80px)
    width: rem(300px)
    position: fixed
    right: -230px
    top: 50%
    border-radius: 40px 0 0 40px
    cursor: pointer
    &:hover
      right: -30px
    p
      color: $black
      font-weight: bold
      text-align: center
      font-size: 15px
    img
      height: rem(80px)

.rrss
  height: rem(50px)
  background-color: $black
  position: relative
  img
    &.insta
      width: rem(35px)
    &.fb
      width: rem(25px)
  .disclaimer
    color: $white
    @include font-size(15px)
    
#contacto
  input, textarea
    border: 0px
  ::-webkit-input-placeholder
   text-align: center
   color: $white
  :-moz-placeholder
    text-align: center  
    color: $white
  ::-moz-placeholder
    text-align: center
    color: $white
  :-ms-input-placeholder  
    text-align: center
    color: $white

  input, textarea
    margin-bottom: rem(30px)

#contacto
  p
    color: $white
    font-size: 10px

