@font-face
  font-family: 'Gotham'
  src:  url(./fonts/gotham/Gotham-Bold.woff) format("woff"), url(./fonts/gotham/Gotham-Bold.otf) format("opentype"),url(./fonts/gotham/GothamBold.ttf) format("truetype")
  font-weight: 500

@font-face
  font-family: 'Gotham'
  src:  url(./fonts/gotham/GothamMedium.woff) format("woff"), url(./fonts/gotham/GothamMedium.otf) format("opentype"),url(./fonts/gotham/GothamMedium.ttf) format("truetype")
  font-weight: 400

@font-face
  font-family: 'Gotham'
  src:  url(./fonts/gotham/Gotham-Thin.woff) format("woff"),url(./fonts/gotham/Gotham-Thin.otf) format("opentype"),url(./fonts/gotham/Gotham-Thin.ttf) format("truetype")
  font-weight: 300


@font-face
  font-family: 'Gotham'
  src:  url(./fonts/gotham/Gotham-Black.woff) format("woff"),url(./fonts/gotham/Gotham-Black.otf) format("opentype"),url(./fonts/gotham/Gotham-Black.ttf) format("truetype")
  font-weight: 700
